@media only screen and (max-width: 1045px) {
  .custom-header {
    #logo {
      width: 150px;
    }
  }
}

@media only screen and (max-width: 970px) {
  .custom-header {
    #logo {
      width: 150px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .custom-header {
    #logo {
      width: 120px;
    }
    .hamburger {
      margin: 30px 20px 0 0;
      .fa-bars {
        height: 25px;
        width: auto;
      }
    }
  }
  .spinner {
    width: auto;
    padding: 5px;
  }
  .custom-footer {
    .fa-twitter,
    .fa-book,
    .fa-github {
      font-size: 0.8rem;
    }
  }
}

@media only screen and (max-width: 375px) {
  .custom-header {
    #logo {
      width: 70px;
    }
    .hamburger {
      margin: 25px 20px 0 0;
      .fa-bars {
        height: 20px;
      }
    }
  }

  .custom-footer {
    margin: 0 20px 20px 0;
  }
}

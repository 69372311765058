@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Texturina:wght@300;400;700&display=swap');

p {
  line-height: 1.5;
}

.main {
  height: 100vh;
  width: 100vw;
  background-color: #fffffe;
  font-family: 'JetBrains Mono', monospace;
}

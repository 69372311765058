.custom-header {
  .hamburger {
    position: absolute;
    top: 0;
    right: 0;
    margin: 30px 30px 0 0;
    z-index: 1;

    .fa-bars {
      color: #ff3864;
      transition: all 1s ease-out;
    }

    .fa-bars:hover {
      transform: rotateZ(90deg);
      transition: all 1s ease-out;
      cursor: pointer;
    }
  }
  .nav-links {
    position: fixed;
    background: #000;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    clip-path: circle(100px at 90% -20%);
    -webkit-clip-path: circle(100px at 90% -20%);
    transition: all 2s ease-out;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    li {
      list-style: none;
      opacity: 0;
      transition: all 0.5s ease 0.4s;
      a {
        font-size: 25px;
        color: #ff3864;
        text-decoration: none;
      }
      a::after {
        transition: all ease-in-out 0.2s;
        background: none repeat scroll 0 0 #ff3864;
        content: '';
        display: block;
        height: 2px;
        width: 0;
      }
      a:hover::after {
        width: 100%;
      }
    }
    li.fade {
      opacity: 1;
    }
  }
  .nav-links.open {
    clip-path: circle(2000px at 90% -10%);
    -webkit-clip-path: circle(2000px at 90% -10%);
    pointer-events: all;
  }
}

.custom-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  .fa-twitter,
  .fa-book,
  .fa-github {
    color: #ff3864;
    margin-right: 20px;
    margin-bottom: 20px;
    transition: transform 0.2s;
  }
  .fa-twitter:hover,
  .fa-book:hover,
  .fa-github:hover {
    transform: scale(1.2);
  }
}

.spinner {
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  width: 350px;
  text-align: center;
  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #ff3864;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
